// companiesActions.js
import {
  fetchCompaniesStart,
  fetchCompaniesSuccess,
  fetchCompaniesFailure,
  createCompanyStart,
  createCompanySuccess,
  createCompanyFailure,
  updateCompanyStart,
  updateCompanySuccess,
  updateCompanyFailure,
  deleteCompanyFailure
} from "../slices/companiesSlice";
import { createCompanyAddressAPI, createCompanyUserAddressAPI, updateCompanyAddressAPI, updateCompanyUserAddressAPI } from "../../api/companyUserAddress.api.js";
import {
  createCompanyAPI,
  updateCompanyAPI,
  fetchCompaniesAPI,
  deleteCompanyAPI,
  createCompanyDocumentAPI
} from "../../api/companies.api";
import { fetchCompanySuccess } from "../slices/verifyOtpSlice.js";

export const fetchCompanies = () => async (dispatch) => {
  try {
    dispatch(fetchCompaniesStart());
    const companies = await fetchCompaniesAPI(dispatch);
    dispatch(fetchCompaniesSuccess(companies));

  } catch (error) {
    dispatch(fetchCompaniesFailure(error.message));
  }
};
export const deleteCompany = (id) => async (dispatch) => {
  try {
    dispatch(fetchCompaniesStart());
    await deleteCompanyAPI(id, dispatch);

  } catch (error) {
    dispatch(deleteCompanyFailure(error.message));
  }
};
export const createCompany = (companyData, addrData, documents) => async (dispatch) => {
  try {
    dispatch(createCompanyStart());
    let res = await createCompanyAPI(companyData, dispatch);
    await createCompanyAddressAPI(addrData, res.data.companyId, dispatch)
    await Promise.all(
      documents.map(async (doc) => {
        const formData = new FormData();
        formData.append("file", doc.file);
        formData.append("name", doc.name);
        formData.append("type", doc.type);
        formData.append("entity", res.data.companyId);
        formData.append("entityModel", "company");
        await createCompanyDocumentAPI(formData, res.data.companyId, dispatch);
      })
    );

    dispatch(createCompanySuccess());
  } catch (error) {
    dispatch(createCompanyFailure(error.message));
  }
};

export const updateCompany = (companyData, addrData, documents) => async (dispatch) => {
  try {
    dispatch(updateCompanyStart());
    let res = await updateCompanyAPI(companyData, dispatch);

    let updatedAddress = {}
    if (addrData._id === 0) {
      delete addrData._id;
      updatedAddress = await createCompanyAddressAPI(addrData, res.data.companyId, dispatch)
    }
    else {
      updatedAddress = await updateCompanyAddressAPI(addrData, res.data.companyId, dispatch);
    }
    res.data.addresses = [{ _id: updatedAddress.data.id, ...updatedAddress.data }]
    if (documents.length > 0)
      await Promise.all(
        documents.map(async (doc) => {
          const formData = new FormData();
          formData.append("file", doc.file);
          formData.append("name", doc.name);
          formData.append("type", doc.type);
          formData.append("entity", res.data.companyId);
          formData.append("entityModel", "company");
          await createCompanyDocumentAPI(formData, res.data.companyId, dispatch);
        })
      );
    dispatch(fetchCompanySuccess(res.data));
  } catch (error) {
    dispatch(updateCompanyFailure(error.message));
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchImage } from '../../api/imageUpload.api';
import { fetchCategoriesAPI, fetchSubCategoriesByCategoryIDAPI } from "../../api/services.api";
import Dropdown from 'react-bootstrap/Dropdown';
import { AppIcons, AppImages } from "../../assets/";
import { useDispatch } from "react-redux";

function SubCategoryCard({ categoryName, subCategory, onEdit }) {
    const { icon, isActive } = subCategory;
    const [imageUrl, setImageUrl] = useState('');
    const [isLoading, setIsLoading] = useState(true); // Loading state

    useEffect(() => {
        // Fetch the image and set it once loaded
        fetchImage(icon)
            .then((img) => {
                setImageUrl(img);
                setIsLoading(false); // Image is loaded
            })
            .catch(() => {
                setImageUrl(AppImages.tempCat); // Fallback in case of an error
                setIsLoading(false); // Stop loading
            });
    }, [icon]);

    return (
        <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow px-3 py-3 rounded-2xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-4">
                <div className="flex gap-5 justify-between w-full text-base tracking-widest max-md:mr-2.5">
                    <div className="flex gap-1 justify-between">
                        {/* Conditional rendering: Show loading icon or the image */}
                        {isLoading ? (
                            <img
                                loading="lazy"
                                src={AppIcons.tempCat} // Your loading icon here
                                alt="loading"
                                className="flex-shrink-0 rounded-full w-[59px] h-[59px] object-cover"
                            />
                        ) : (
                            <img
                                loading="lazy"
                                src={imageUrl || AppImages.tempCat} // Fallback if imageUrl is empty
                                alt={subCategory.name}
                                className="flex-shrink-0 rounded-full w-[59px] h-[59px] object-cover"
                            />
                        )}
                        <div className="my-auto text-black ml-2 font-bold text-sm leading-normal tracking-wider">
                            {subCategory.name}
                        </div>
                    </div>
                    <button
                        className="shrink-0 my-auto aspect-square w-[21px]"
                        onClick={() => onEdit(subCategory)}
                    >
                        <img loading="lazy" src={AppIcons.Edit} alt="" />
                    </button>
                </div>
                <p
                    className={`${isActive == false ? "mt-2 text-red-500" : "mt-2 text-green-500"
                        }`}
                >
                    {isActive == false ? "In Active" : "Active"}
                </p>
                <div className="flex flex-col mt-2">
                    <div className="flex text-sm flex-row justify-between">
                        <p className="text-gray-500">Category</p>
                        <p>{categoryName}</p>
                    </div>
                </div>
                <div className="mt-2 text-sm leading-6 text-black description">
                    {subCategory.description}
                </div>
            </div>
        </div>
    );
}

function SubCategory() {
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [category, setCategory] = useState(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch categories once when the component mounts
        fetchCategoriesAPI(dispatch)
            .then((res) => {
                setCategories(res.data);

                // Select the first category by default if categories are available
                if (res.data && res.data.length > 0) {
                    const firstCategory = res.data[0];
                    setCategory(firstCategory);  // Set the first category
                    // Fetch subcategories for the first category
                    fetchSubCategoriesByCategoryIDAPI(firstCategory.id, dispatch)
                        .then((data) => {
                            setSubCategories(data.data); // Set the fetched subcategories in state
                        })
                        .catch((error) => {
                            console.error("Error fetching subcategories:", error);
                        });
                }
            })
            .catch((error) => {
                console.error("Error fetching categories:", error);
            });
    }, []);

    const categorySelected = (obj) => {
        setCategory(obj);

        // Fetch subcategories based on the selected category
        fetchSubCategoriesByCategoryIDAPI(obj.id, dispatch)
            .then((data) => {
                setSubCategories(data.data); // Set the fetched subcategories in state
            })
            .catch((error) => {
                console.error("Error fetching subcategories:", error);
            });
    };

    function handleAddSubCategory() {
        console.log("Adding a new subCategory");
        navigate("/subCategories/new");
    }

    function handleEditSubCategory(subCategory) {
        console.log("Editing subCategory:", subCategory);
        navigate(`/subCategories/edit/${subCategory?.id}`, { state: subCategory });
    }

    return (
        <section className="flex flex-col px-4 pt-7 pb-20 bg-white rounded-2xl shadow-sm max-md:px-4 max-md:mt-10 max-md:max-w-full">
            <header className="logo-nav-flexbox-container flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
                <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Category"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Category
                    </label>
                    <div className="grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
                        <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
                            <Dropdown.Toggle className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                                {category == null ? 'Select Category' : category.name}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="btn-dropdown-list-halo">
                                {categories && categories.filter(x => x.isActive == true).map((category, index) => (
                                    <Dropdown.Item key={index} onClick={() => categorySelected(category)}>
                                        {category.name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <button
                    onClick={handleAddSubCategory}
                    className="justify-center px-3 py-2 text-sm text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] whitespace-nowrap"
                >
                    Add New
                </button>
            </header>
            <div className="mt-8 max-md:mb-10 max-md:max-w-full">
                <div className="flex flex-wrap gap-2 max-md:flex-col max-md:gap-0">
                    {subCategories && subCategories.map((subCategory, index) => (
                        <SubCategoryCard
                            key={index}
                            categoryName={categories.length > 0 ? categories.find(x => x.id === subCategory.category)?.name : ''}
                            subCategory={subCategory}
                            onEdit={handleEditSubCategory}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

export default SubCategory;

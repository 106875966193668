/* eslint-disable react/prop-types */

import { useParams } from "react-router-dom";

import { MdOutlineExpandMore } from "react-icons/md";
import { useState } from "react";
const TableGrid = ({ heading, value, arrayStatus = false, arr = [] }) => {
  return (
    <div className="w-full flex justify-between items-center">
      <span className="text-gray-600 font-medium w-1/2">{heading}</span>
      <span className="text-right text-black font-bold w-1/2">
        {arrayStatus ? (
          <ul className="mt-0 pl-4 list-none">
            {arr.map((val, i) => (
              <li key={val + i + Math.random()}>{val}</li>
            ))}
          </ul>
        ) : (
          value
        )}
      </span>
    </div>
  );
};

const Accordion = ({ children, expanded, toggle }) => {
  return (
    <div
      className={`border rounded-lg bg-blue-50 ${expanded ? "shadow-sm" : ""}`}
    >
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={toggle}
      >
        <h6 className="text-lg font-bold text-black">Sub Bookings</h6>
        <span className={`${expanded ? "transform rotate-180" : ""}`}>
          <MdOutlineExpandMore />
        </span>
      </div>
      {expanded && <div className="p-4 border-t">{children}</div>}
    </div>
  );
};

function BookingDetail({selectedBooking}) {
  const { id } = useParams();
  const [isExpanded, setIsExpanded] = useState(false); // State for controlling the accordion
  const FormatedTime = (milli) => {
    const date = new Date(milli);

    // Format the date to HH:mm AM/PM format
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedTime;
  }
  const FormatedDate = (milli) => {
    const date = new Date(milli);

    // Format the date to "18 Oct 2024"
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
    return formattedDate;
  }
  const GetDuration = (start,end) => {
    const startDateTime = new Date(start);
    const endDateTime = new Date(end);
    // Format the date to HH:mm AM/PM format
   
    const differenceInMs = endDateTime - startDateTime;

    // Convert milliseconds to hours
    const durationInHours = differenceInMs / (1000 * 60 * 60);
  
    return durationInHours;
  }

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div className="w-full h-full">
      <div className="max-w-7xl mx-auto bg-white rounded-lg shadow-md p-1 md:p-2">
        {/* Booking Details Header Section */}
        <div className="rounded-lg">
          <div className="p-4">
            {/* Booking Info Grid */}
            <div className="grid grid-cols-1 gap-2">
              <div className="w-full flex justify-between items-center">
                <h2 className="text-black font-bold text-lg w-1/2">
                  Booking Details
                </h2>
                <span className="text-gray-600 text-right font-normal text-lg w-1/2">
                  Status – <span className="text-black font-bold">{selectedBooking.status}</span>
                </span>
              </div>
              <span className="text-gray-500 mb-2 block">
              Booking ID: <span className="font-bold text-black">{selectedBooking._id.slice(-6).toUpperCase()}</span>
            </span>
              <TableGrid
                heading={"Address"}
                value={"("+selectedBooking.address.addressType+") " + selectedBooking.address.aptNo + " , " + selectedBooking.address.street + " , "+ selectedBooking.address.city + " , " + selectedBooking.address.country}
              />
              <TableGrid heading={"Contact Number"} value={selectedBooking.customer.phone.countryCode +" " +selectedBooking.customer.phone.phoneNumber}  />
              
              <TableGrid heading={"Duration"} value={GetDuration(selectedBooking.startTime, selectedBooking.endTime)} />
              <TableGrid
                heading={"Date & Start Time"}
                value={FormatedDate(selectedBooking.date)+", "+FormatedTime(selectedBooking.startTime)+" - "+FormatedTime(selectedBooking.startTime + (1000 * 60 * 30))}
              />

              <TableGrid
                heading={"Instructions"}
                value={selectedBooking.description}
              />
            </div>
          </div>
        </div>
        <Accordion expanded={isExpanded} toggle={toggleAccordion}>

          {selectedBooking.subBookings.map(subBooking=><div className="mb-4">
            <span className="text-gray-500 mb-2 block">
              Sub-Booking ID: <span className="font-bold text-black">{subBooking._id.slice(-6).toUpperCase()}</span>
            </span>
            <div className="grid grid-cols-1 gap-2">
              <TableGrid heading={"Status:"} value={subBooking.status} />
              <TableGrid heading={"Professional Name:"} value={subBooking.professional.firstName + " "+ subBooking.professional.lastName} />
              <TableGrid
                heading={"Service:"}
                value={""}
                arrayStatus={true}
                arr={subBooking.services.map(s=>(s.quantity + "x" + " " + s.service.name))}
              />
              <TableGrid heading={"Amount:"} value={"AED " +subBooking.amount} />
            </div>
            <div className="border-t border-gray-300 my-2"></div>{" "}
            {/* Divider */}
          </div>)}
          
          

          
        </Accordion>

        {/* Payment Summary Section */}
        <div className="rounded-lg mt-4">
          <div className="p-4">
            <h3 className="text-black font-bold text-lg">Payment Summary</h3>
            <div className="grid grid-cols-1 gap-1 mt-2">
              <TableGrid heading={"Total Booking Amount"} value={"AED " + selectedBooking.totalAmount} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookingDetail;

import {fetchAllEntities} from "./baseHalo.api"

export const fetchStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };


  export const fetchBookingStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats/bookings", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchBookingStatsByCategoryIdAPI = async (categoryId,dispatch) => {
    try {
      const response = await fetchAllEntities("stats/bookings?categoryId=" + categoryId, dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchCustomerStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats/top-customers", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchProfessionalStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats/top-professionals", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchRevenueStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats/revenue", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };

  export const fetchTopCompaniesStatsAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("stats/top-companies", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  
import React from "react";
import Header from "../components/Header";
import DashboardPanel from "../components/DashboardPanel";
import { useSelector } from "react-redux";
import { selectApp } from "../redux/slices/appSlice";
import { Spinner } from 'react-bootstrap';

const MainLayout = ({ children }) => {
  const { isAppLoading } = useSelector(selectApp);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#FAFBFF",
        position: "relative", // Ensure positioning context
      }}
    >
      <Header />
      <div className="flex flex-row flex-1 pt-20">
        <DashboardPanel />
        <div className="flex-1 pl-0 xl:pl-[290px] mx-4 my-4 h-[calc(100vh-80px)]">
          <div>
            {children}
          </div>
          {isAppLoading && (
            <div
              style={{
                position: "absolute", // Position it over the content
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)", // Add some opacity to dim the background
                zIndex: 1000, // Ensure it appears above other content
              }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;

import { createTheme, responsiveFontSizes } from "@mui/material";

var theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffffff",
    },
    success: {
      main: "#696969",
    },
    error: {
      main: "#A8A8A8",
    },
    background: {
      default: "#f5f5f5",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: "DM Sans, sans-serif", // Updated font family
    h1: {
      fontSize: "25px",
      fontWeight: 800,
    },
    h2: {
      fontSize: "22px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "20px",
      fontWeight: 600,
    },
    h4: {
      fontSize: "18px",
      fontWeight: 600,
    },
    h5: {
      fontSize: "16px",
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      fontWeight: 600,
    },
    p: {
      fontSize: "14px",
      fontWeight: 300,
    },
    body1: {
      fontSize: "16px",
      fontWeight: 300,
      color: "#000",
    },
    body2: {
      fontSize: "14px",
      fontWeight: 700,
      color: "#41405D",
    },
    body3: {
      fontSize: "16px",
      fontWeight: 400,
    },
    body1Bold: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#000",
    },
    body2Bold: {
      fontSize: "14px",
      fontWeight: 600,
      color: "#000",
    },
    autoText1: {
      fontSize: "12px",
      fontWeight: "600",
    },
    autoTextThin: {
      fontSize: "13px",
      fontWeight: "300",
    },
    autoText2: {
      fontSize: "13px",
      fontWeight: "400",
    },
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          background:
            "linear-gradient(276.23deg, #F0BA47 8.16%, #F3D25C 63.6%)",
          fontSize: "15px",
          fontWeight: "400",
          textTransform: "capitalize",
          fontFamily: "DM Sans, sans-serif", // Updated font family for button
          borderRadius: "10px",
          height: "40px",
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background:
              "linear-gradient(276.23deg, #F0BA47 8.16%, #F3D25C 63.6%)",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          fontSize: "16px", // Adjust as needed for icon sizing
          borderRadius: "50%", // Rounded for icon button style
          "&.Mui-disabled": {
            color: "#979EA7",
          },
        },
      },
    },
  },
});




theme = responsiveFontSizes(theme);

export default theme;

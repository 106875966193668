import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Paper,
  Drawer
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { fetchBookingsAPI } from "../api/bookings.api";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import BookingDetail from "./BookingDetail";

const statusColors = {
  Start: "linear-gradient(91deg,#01A0AA 1.65%,#076B9E 100%)",
  Started: "linear-gradient(91deg,#01A0AA 1.65%,#076B9E 100%)",
  Completed: "linear-gradient(276deg,#F0BA47 8.16%,#F3D25C 63.6%)",
  Cancelled: "#FC2C5A",
};

const ITEMS_PER_PAGE = 10;

function Appointments() {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState({
    // sortField:"createdAt",
    bookingId: "",
    customerName: "",
    customerId: "",
    status: "",
    

  });

  const [page, setPage] = useState(1); // start at page 1
  const [selectedBooking, setSelectedBooking] = useState({})
  const [drawerOpen, setDrawerOpen] = useState(false);  


  const handlePageChange = (value) => {
    if (value < 1 || value > pageCount) return;
    setPage(value);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageLimit = 5; // Number of pages to show at once

    // Always show the first page, the current page, some surrounding pages, and the last page
    if (pageCount <= pageLimit) {
      // If total pages are less than the limit, show all pages
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${1 === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          1
        </button>
      );

      // Show ellipsis if current page is far from the first page
      if (page > 3) {
        pageNumbers.push(<span key="start-ellipsis">...</span>);
      }

      // Show pages around the current page
      const startPage = Math.max(2, page - 1); // Start from one before the current page
      const endPage = Math.min(pageCount - 1, page + 1); // End at one after the current page

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is far from the last page
      if (page < pageCount - 2) {
        pageNumbers.push(<span key="end-ellipsis">...</span>);
      }

      // Show the last page
      pageNumbers.push(
        <button
          key={pageCount}
          onClick={() => handlePageChange(pageCount)}
          className={`mx-1 px-3 py-1 rounded ${pageCount === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          {pageCount}
        </button>
      );
    }

    return pageNumbers;
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };
  const handleRowClick = (row) => {
    setSelectedBooking(row);
    console.log(row)
    setDrawerOpen(true);
  };


  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBookingsAPI(
          page,
          ITEMS_PER_PAGE,
          filters,
          dispatch
        );
        // const sortedBookings = response.data.bookings.sort((a, b) => {
        //   return new Date(b.date) - new Date(a.date);
        // });
        const rowsPerPage = 10;
        setPageCount(Math.ceil(response.data.totalBookings / rowsPerPage));

        setAppointments(response.data.bookings);
        setTotalPages(Math.ceil(response.data.totalBookings / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    setPage(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  const handleSearchClick = () => {
    const fetchData = async () => {
      try {

        if (filters.bookingId.length < 6) {
          filters.bookingId = ""
        }
        const response = await fetchBookingsAPI(
          page,
          ITEMS_PER_PAGE,
          filters,
          dispatch
        );
        const sortedBookings = response.data.bookings.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        const rowsPerPage = 10;
        setPageCount(Math.ceil(response.data.totalBookings / rowsPerPage));

        setAppointments(sortedBookings);
        setTotalPages(Math.ceil(response.data.totalBookings / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }



  return (
    <div className="overflow-auto rounded-xl shadow-sm p-8 bg-white">
      <div className="mb-4 flex gap-4">

        <input
          type="text"
          name="bookingId"
          autoComplete="off"
          placeholder="Booking Id"
          value={filters.bookingId}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />

        <input
          type="text"
          name="customerName"
          autoComplete="off"
          placeholder="Customer Name"
          value={filters.customerName}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
        <input
          type="text"
          name="customerId"
          autoComplete="off"
          placeholder="Customer ID"
          value={filters.customerId}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />

        <select
          name="status"
          value={filters.status}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        >
          <option value="">Status</option>
          <option value="Pending">Pending</option>
          <option value="Confirmed">Confirmed</option>
          <option value="Started">Started</option>
          <option value="Completed">Completed</option>
          <option value="Cancelled">Cancelled</option>
        </select>
        <button
          className="justify-center px-3 py-2 text-base text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] max-md:px-5"
          onClick={handleSearchClick}
        >
          Search
        </button>
      </div>
      <Drawer anchor="right" PaperProps={{
    style: { width: '700px' }  // Adjust the width here
  }} open={drawerOpen} onClose={() => toggleDrawer(false)}>
        <div><BookingDetail  selectedBooking={selectedBooking} /></div>
      </Drawer>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }} />
                <TableCell sx={{ fontWeight: 'bold' }}>Booking Id</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Customer Name</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Date</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Start Time</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>End Time</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((booking) => (
                <BookingRow key={booking._id} booking={booking} handleRowClick={handleRowClick} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <div className="flex justify-center py-4">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowBack
            style={{
              color: "#076B9E",
            }}
          />
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowForward
            style={{
              color: "#076B9E",
            }}
          />
        </button>
      </div>

    </div>
  );
}

function BookingRow({ booking,handleRowClick }) {
  const [open, setOpen] = useState(false);
  const FormatedTime = (milli) => {
    const date = new Date(milli);

    // Format the date to HH:mm AM/PM format
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
    return formattedTime;
  }
  const FormatedDate = (milli) => {
    const date = new Date(milli);

    // Format the date to "18 Oct 2024"
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
    return formattedDate;
  }

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{booking._id.slice(-6).toUpperCase()}</TableCell>
        <TableCell>{`${booking.customer.firstName || ""} ${booking.customer.lastName || ""}`}</TableCell>
        <TableCell>{FormatedDate(booking.date)}</TableCell>
        <TableCell>{FormatedTime(booking.startTime)}</TableCell>
        <TableCell>{FormatedTime(booking.endTime)}</TableCell>
        <TableCell>{booking.status}</TableCell>
        <TableCell>
          <button
            className="text-blue-600 hover:underline"
            onClick={() => handleRowClick(booking)}
          >
            Details
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>Sub-Booking ID</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Professional Name</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Services</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {booking.subBookings.map((subBooking) => (
                  <SubBookingRow key={subBooking._id} subBooking={subBooking} />
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function SubBookingRow({ subBooking }) {
  return (
    <TableRow className="sub-booking-row">
      <TableCell>{subBooking._id.slice(-6).toUpperCase()}</TableCell>
      <TableCell>{subBooking.professional ? `${subBooking.professional.firstName} ${subBooking.professional.lastName}` : "Unknown"}</TableCell>
      <TableCell>
        {subBooking.services.map((service) => service.service.name).join(", ")}
      </TableCell>
      <TableCell>{subBooking.status}</TableCell>
    </TableRow>
  );
}

export default Appointments;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppIcons, AppImages } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import Datepicker from 'flowbite-datepicker/Datepicker';
import { useDispatch, useSelector } from "react-redux";
// import { selectLogin } from "../../redux/slices/loginSlice";
import { createCompany, deleteCompany, updateCompany } from "../../redux/actions/companiesActions";
import { uploadImage, fetchImage } from "../../api/imageUpload.api";
import { fetchCategoriesAPI } from "../../api/services.api";
import DropdownSelector from "../../components/DropDownSelector";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { fetchAllDocumentsAPI } from "../../api/companies.api";
import { fetchDocument } from "../../api/baseHalo.api";
import AppConfig from "../../config"

function CompanyImage({ companyPicture, onChangeCompanyPicture }) {
    return (
        <div className="flex gap-3 justify-between self-start mt-8 font-semibold tracking-widest text-center text-white max-md:mt-5">
            <img
                loading="lazy"
                src={companyPicture || AppImages.Placeholder}
                alt="Company Image"
                className="shrink-0 max-w-full rounded-xl border-solid aspect-rectangle border-[7px] border-zinc-100 w-[250px]"
            />
            {AppConfig.IS_COMP ? <div></div> : <button
                onClick={onChangeCompanyPicture}
                className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
            >
                Change Picture
            </button>}

        </div>
    );
}
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});
const DocumentType = Object.freeze({
    TRADING_LICENSE: "trading_license",
    EMIRATES_ID: "emirates_id",
    INSURRANCE: "insurrance",
    RISK_ASSESEMENT: "risk_assesement",
    ADDRESS_IJARI: "address_ijari",
    VAT_REG_CERT: "vat_registration_certificate",
});
function AddEditCompany() {
    const location = useLocation();
    const company = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [companyName, setCompanyName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [category, setCategory] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [city, setCity] = useState("");
    const [street, setStreet] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [aptNo, setAptNo] = useState("");
    const [defAddrId, setDefAddrId] = useState(0);
    const [doe, setDoe] = useState("");
    const [description, setDescription] = useState("");
    const [companyPicture, setCompanyPicture] = useState(null);
    const [companyPictureUrl, setCompanyPictureUrl] = useState(null)
    const [imageUpdate, setimageUpdate] = useState(false)
    const [isActive, setIsActive] = useState(false)
    const [categories, setCategories] = useState([])
    const [tradingLicense, setTradingLicense] = useState(null);
    const [emiratesID, setEmiratesID] = useState(null);
    const [insurance, setInsurance] = useState(null);
    const [riskAssessment, setRiskAssessment] = useState(null);
    const [ijari, setIjari] = useState(null);
    const [vatRegCert, setVatRegCert] = useState(null);
    const [allDocuments, setAllDocuments] = useState(null)




    const center = {
        lat: 25.276987,
        lng: 55.296249,
    };
    useEffect(() => {
        if (company) {
            setCompanyName(company.name);
            setPhone(company.phone.phoneNumber);
            setDescription(company.description);
            setEmail(company.email)
            setDoe(company.doe)
            setIsActive(company.isActive)
            setCategoryId(company.category)
            if (company.companyImageBG)
                fetchImage(company.companyImageBG)
                    .then((img) => setCompanyPictureUrl(img))


            if (company.addresses && company.addresses.length > 0) {
                let defAddr = company.addresses[0];
                setCity(defAddr.city);
                setPostalCode(defAddr.postalCode)
                setStreet(defAddr.street)
                setAptNo(defAddr.aptNo)
                setDefAddrId(defAddr._id)
            }
            const fetchCategories = async () => {

                let res = await fetchCategoriesAPI(dispatch);
                setCategory(res.data.find(x => x.id === company.category))
                setCategories(res.data.filter(x => x.isActive == true));

            };
            const fetchDocuments = async () => {

                debugger;
                let res = await fetchAllDocumentsAPI(company.companyId, dispatch);
                debugger;
                setAllDocuments(res.data);
                res.data.map((doc) => {
                    if (doc.type === DocumentType.TRADING_LICENSE) {
                        setTradingLicense({ name: doc.name, type: DocumentType.TRADING_LICENSE, isServer: true, serverId: doc._id })
                    }
                    else if (doc.type === DocumentType.EMIRATES_ID) {
                        setEmiratesID({ name: doc.name, type: DocumentType.EMIRATES_ID, isServer: true, serverId: doc._id })
                    }
                    else if (doc.type === DocumentType.INSURRANCE) {
                        setInsurance({ name: doc.name, type: DocumentType.INSURRANCE, isServer: true, serverId: doc._id })
                    }
                    else if (doc.type === DocumentType.RISK_ASSESEMENT) {
                        setRiskAssessment({ name: doc.name, type: DocumentType.RISK_ASSESEMENT, isServer: true, serverId: doc._id })
                    }
                    else if (doc.type === DocumentType.ADDRESS_IJARI) {
                        setIjari({ name: doc.name, type: DocumentType.ADDRESS_IJARI, isServer: true, serverId: doc._id })
                    }
                    else if (doc.type === DocumentType.VAT_REG_CERT) {
                        setVatRegCert({ name: doc.name, type: DocumentType.VAT_REG_CERT, isServer: true, serverId: doc._id })
                    }

                })
            };
            fetchCategories();
            fetchDocuments()
        }
    }, [company]);
    useEffect(() => {
        const fetchCategories = async () => {
            let res = await fetchCategoriesAPI(dispatch);

            setCategories(res.data.filter(x => x.isActive == true));

            if (categoryId)
                setCategory(res.data.find(x => x.id === categoryId))
        };
        fetchCategories();

    }, [])

    const handleCompanyNameChange = (e) => {
        setCompanyName(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const handleDoeChange = (e) => {
        setDoe(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const handleDeleteCompanyClick = () => {
        dispatch(deleteCompany(company.companyId))
        navigate(-1)
    };

    const handleChangeCompanyPicture = () => {
        pickFile([FileTypes.IMAGE], (result) => {
            // Handle the selected file result here
            console.log(result);
            setimageUpdate(true)
            setCompanyPicture(result);
            setCompanyPictureUrl(URL.createObjectURL(result))
        });
    };
    const handleActiveToggle = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    }
    const validateDocuments = () => {
        if (!tradingLicense) {
            toast.warning("Trading License is mandatory.");
            return false;
        }
        if (!emiratesID) {
            toast.warning("Emirates ID is mandatory.");
            return false;
        }
        if (!insurance) {
            toast.warning("Insurance is mandatory.");
            return false;
        }
        if (!riskAssessment) {
            toast.warning("Risk Assessment is mandatory.");
            return false;
        }
        if (!ijari) {
            toast.warning("Ijari is mandatory.");
            return false;
        }
        if (!vatRegCert) {
            toast.warning("VAT Registration Certificate is mandatory.");
            return false;
        }
        return true;
    };
    const handleSave = async () => {
        if (!validateDocuments()) return;
        debugger;
        let documents = [];
        if (tradingLicense && tradingLicense.isServer === false) documents.push(tradingLicense);
        if (emiratesID && emiratesID.isServer === false) documents.push(emiratesID);
        if (insurance && insurance.isServer === false) documents.push(insurance);
        if (riskAssessment && riskAssessment.isServer === false) documents.push(riskAssessment);
        if (ijari && ijari.isServer === false) documents.push(ijari);
        if (vatRegCert && vatRegCert.isServer === false) documents.push(vatRegCert);

        const addressData = {
            category: category.id,
            city: city,
            street: street,
            aptNo: aptNo,
            postalCode: postalCode,
            country: "UAE",
            latitude: center.lat,
            longitude: center.lng,
        };

        try {
            if (company) {
                // Update logic
                if (imageUpdate) {
                    const imageReturn = await uploadImage('companyList', 'companyImage', companyPicture);
                    await dispatch(updateCompany({
                        companyId: company.companyId,
                        companyImageBG: imageReturn.data.path,
                        name: companyName,
                        isActive: isActive,
                        email: email,
                        category: category.id,
                        doe: doe,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone,
                            _id: company.phone._id
                        }
                    }, { _id: defAddrId, ...addressData }, documents));
                } else {
                    await dispatch(updateCompany({
                        companyId: company.companyId,
                        name: companyName,
                        isActive: isActive,
                        email: email,
                        category: category.id,
                        doe: doe,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone,
                            _id: company.phone._id
                        }
                    }, { _id: defAddrId, ...addressData }, documents));
                }
            } else {
                // Create logic
                if (imageUpdate) {
                    const imageReturn = await uploadImage('companyList', 'companyImage', companyPicture);
                    await dispatch(createCompany({
                        companyImageBG: imageReturn.data.path,
                        doe: doe,
                        category: category.id,
                        name: companyName,
                        isActive: isActive,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone
                        }
                    }, { ...addressData }, documents));
                } else {
                    await dispatch(createCompany({
                        name: companyName,
                        doe: doe,
                        category: category.id,
                        isActive: isActive,
                        phone: {
                            countryCode: '+971',
                            phoneNumber: phone
                        }
                    }, { ...addressData }, documents));
                }
            }

            toast.success("Company saved successfully!");
            navigate("/company-list"); // Navigate to a specific screen, such as the company list
        } catch (error) {
            toast.error("Failed to save company. Please try again.");
            console.error(error);
        }
    }
    const handleCancel = () => {
        // Implement cancel logic here console.log("Cancel button clicked");
    };

    const handleTradingLicenseFileChange = (file) => {
        setTradingLicense({ name: file.name, type: DocumentType.TRADING_LICENSE, isServer: false, file })
    }
    const handleTradingLicenseDelete = () => {
        setTradingLicense(null)
    }
    // Handling file change for Emirates ID
    const handleEmiratesIDFileChange = (file) => {
        setEmiratesID({ name: file.name, type: DocumentType.EMIRATES_ID, isServer: false, file });
    };

    // Handling delete for Emirates ID
    const handleEmiratesIDDelete = () => {
        setEmiratesID(null);
    };

    // Handling file change for Insurance
    const handleInsuranceFileChange = (file) => {
        setInsurance({ name: file.name, type: DocumentType.INSURRANCE, isServer: false, file });
    };

    // Handling delete for Insurance
    const handleInsuranceDelete = () => {
        setInsurance(null);
    };

    // Handling file change for Risk Assessment
    const handleRiskAssessmentFileChange = (file) => {
        setRiskAssessment({ name: file.name, type: DocumentType.RISK_ASSESEMENT, isServer: false, file });
    };

    // Handling delete for Risk Assessment
    const handleRiskAssessmentDelete = () => {
        setRiskAssessment(null);
    };

    // Handling file change for Ijari
    const handleIjariFileChange = (file) => {
        setIjari({ name: file.name, type: DocumentType.ADDRESS_IJARI, isServer: false, file });
    };

    // Handling delete for Ijari
    const handleIjariDelete = () => {
        setIjari(null);
    };

    // Handling file change for VAT Registration Certificate
    const handleVatRegCertFileChange = (file) => {
        setVatRegCert({ name: file.name, type: DocumentType.VAT_REG_CERT, isServer: false, file });
    };

    // Handling delete for VAT Registration Certificate
    const handleVatRegCertDelete = () => {
        setVatRegCert(null);
    };
    const handleDownload = async (obj) => {
        if (obj.isServer) {
            let response = await fetchDocument("documents", obj.serverId, dispatch)
            debugger;
            // const blob = await response.blob();
            const url = URL.createObjectURL(response);
            window.open(url, "_blank");
        }
        else if (obj.file) {
            const fileURL = URL.createObjectURL(obj.file); // Create a URL for the file
            window.open(fileURL, '_blank'); // Open the file in a new tab
        }
    };


    return (
        <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-8 max-md:max-w-full">

            <header className="flex gap-5 justify-between font-bold max-md:flex-wrap max-md:max-w-full">
                <button
                    className="w-31 h-77 px-2 rounded-md flex-shrink-0 rounded-10 bg-blue-100"
                    onClick={() => navigate(-1)}
                >
                    <img loading="lazy" src={AppIcons.ChevronRight} alt="Profile Image" />
                </button>
                {company && !AppConfig.IS_COMP ? <button
                    className="justify-center px-3 py-2 text-sm text-white rounded-lg bg-[#FC2C5A] tracking-[2px] max-md:px-5"
                    onClick={handleDeleteCompanyClick}
                >
                    Delete Company
                </button> : <div></div>}

            </header>

            <CompanyImage
                companyPicture={companyPictureUrl}
                onChangeCompanyPicture={handleChangeCompanyPicture}
            />
            <h4 className="mt-4 text-xl">Personal Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="Name"
                    value={companyName}
                    onChange={handleCompanyNameChange}
                    placeholder="Enter Company Name"
                    disabled={AppConfig.IS_COMP}
                />
                <FieldInput
                    label="Phane"
                    type="tel"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="+971 (----------)"
                    disabled={AppConfig.IS_COMP}
                />
                <FieldInput
                    label="Email"
                    type="Email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Enter Company Email"
                    disabled={AppConfig.IS_COMP}
                />
                {categories && (
                    <DropdownSelector
                        label="Category"
                        items={categories}
                        selectedItem={category}
                        onSelect={setCategory}
                        disabled={AppConfig.IS_COMP}
                    />)}

            </div>
            <h4 className="mt-4 text-xl">Address Information:</h4>
            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="City"
                    value={city}
                    onChange={(obj) => setCity(obj.target.value)}
                    placeholder="Enter City"
                    disabled={AppConfig.IS_COMP}
                />
                <FieldInput
                    label="Postal Code"
                    type="number"
                    value={postalCode}
                    onChange={(obj) => setPostalCode(obj.target.value)}
                    placeholder="Enter Postal Code"
                    disabled={AppConfig.IS_COMP}
                />
                <FieldInput
                    label="Street"
                    value={street}
                    onChange={(obj) => setStreet(obj.target.value)}
                    placeholder="Enter Street"
                    disabled={AppConfig.IS_COMP}
                />

            </div>

            <div className="flex gap-3 items-start  tracking-widest max-md:flex-wrap max-md:max-w-full">
                <FieldInput
                    label="Apartment Number"
                    type="number"
                    value={aptNo}
                    onChange={(obj) => setAptNo(obj.target.value)}
                    placeholder="Enter Apartment Number"
                    disabled={AppConfig.IS_COMP}
                />
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-12 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>

            </div>
            <h4 className="mt-4 text-xl">Documents</h4>
            <div className="flex gap-3 items-start tracking-widest max-md:flex-wrap max-md:max-w-full">
                {/* Trading License Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="Trading License" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        Trading License
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {tradingLicense?.name ? (
                            <>
                                <span className="flex-1 ml-2">{tradingLicense?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" component="label" onClick={async () => await handleDownload(tradingLicense)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleTradingLicenseDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleTradingLicenseFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Emirates ID Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="Emirates ID" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        Emirates ID
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {emiratesID?.name ? (
                            <>
                                <span className="flex-1 ml-2">{emiratesID?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" component="label" onClick={async () => await handleDownload(emiratesID)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleEmiratesIDDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleEmiratesIDFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Insurance Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="Insurance" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        Insurance
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {insurance?.name ? (
                            <>
                                <span className="flex-1 ml-2">{insurance?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" component="label" onClick={async () => await handleDownload(insurance)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleInsuranceDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleInsuranceFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex gap-3 items-start tracking-widest max-md:flex-wrap max-md:max-w-full">
                {/* Risk Assessment Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="Risk Assessment" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        Risk Assessment
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {riskAssessment?.name ? (
                            <>
                                <span className="flex-1 ml-2">{riskAssessment?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" component="label" onClick={async () => await handleDownload(riskAssessment)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleRiskAssessmentDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleRiskAssessmentFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* Ijari Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="Ijari" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        Ijari
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {ijari?.name ? (
                            <>
                                <span className="flex-1 ml-2">{ijari?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" component="label" onClick={async () => await handleDownload(ijari)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleIjariDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleIjariFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>

                {/* VAT Registration Certificate Section */}
                <div className="flex flex-col mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label htmlFor="VAT Registration Certificate" className="text-black font-metropolis text-base font-normal leading-normal tracking-wide">
                        VAT Registration Certificate
                    </label>
                    <div className="flex justify-between items-center px-2 py-2 mt-1.5 rounded-2xl border border-solid border-zinc-400 max-md:px-5 max-md:max-w-full">
                        {vatRegCert?.name ? (
                            <>
                                <span className="flex-1 ml-2">{vatRegCert?.name}</span>
                                <div className="flex items-center gap-2">
                                    <IconButton aria-label="download" size="medium" onClick={async () => await handleDownload(vatRegCert)}>
                                        <FileDownloadOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                    <IconButton aria-label="delete" size="medium" onClick={handleVatRegCertDelete} disabled={AppConfig.IS_COMP}>
                                        <DeleteOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </div>
                            </>
                        ) : (
                            <div className="flex justify-center items-center gap-2 w-full">
                                <IconButton aria-label="upload" size="medium" component="label" disabled={AppConfig.IS_COMP}>
                                    <FileUploadOutlinedIcon fontSize="inherit" />
                                    <VisuallyHiddenInput
                                        type="file"
                                        onChange={(event) => handleVatRegCertFileChange(event.target.files[0])}
                                        multiple
                                    />
                                </IconButton>
                                <span className="text-center">Upload</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <h4 className="mt-4 text-xl">Expiry/Activation</h4>
            <div className="flex gap-3 items-start mt-2  tracking-widest max-md:flex-wrap max-md:max-w-full">

                <div className="flex flex-col  mt-3 text-sm  font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
                    <label
                        htmlFor="Expiry Date"
                        className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
                    >
                        Expiry Date
                    </label>




                    <div class="">
                        <div className=" grow justify-center items-start px-4 py-6 mt-1.5  rounded-3xl border border-solid border-zinc-400  max-md:px-5 max-md:max-w-full">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>

                                <StaticDatePicker value={dayjs(doe)}
                                    onChange={(newValue) => setDoe(newValue)} label="Expiry Date" disabled={AppConfig.IS_COMP} />

                            </LocalizationProvider>

                        </div>

                    </div>
                </div>
                <div className="flex flex-col w-full items-end">
                    <FieldInput
                        label="Active/Inactive"
                        value={isActive}
                        onChange={handleActiveToggle}
                        type="switch"
                        placeholder="Active"
                        disabled={AppConfig.IS_COMP}
                    />
                    {AppConfig.IS_COMP ? <div></div> : <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
                        <button
                            onClick={handleSave}
                            className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
                        >
                            {company ? "Save" : "Add Company"}
                        </button>
                        <button
                            onClick={handleCancel}
                            className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
                        >
                            Cancel
                        </button>
                    </div>}

                </div>
                <div className="flex flex-col w-full items-end">

                    <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">

                    </div>
                </div>



            </div>


        </section>
    );
}

export default AddEditCompany;

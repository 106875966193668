/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import totalncomingImg from "../assets/totalIncoming.png";
import totalOutgoingImg from "../assets/totalOutgoing.png";
import totaProfitImg from "../assets/totalProfit.png";
import totaEarningImg from "../assets/totalEarning.png";
import totaPendingImg from "../assets/totaPending.png";
import { fetchBulkIncomingAPI, fetchBulkOutgoingAPI, fetchPaymentSummeryAPI, fetchTransactionsAPI } from "../api/transactions.api";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const ITEMS_PER_PAGE = 10;
const IncomingComponent = ({ header }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState({
    senderName: "",
    senderId: ""
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePageChange = (value) => {
    if (value < 1 || value > pageCount) return;
    setPage(value);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageLimit = 5; // Number of pages to show at once

    // Always show the first page, the current page, some surrounding pages, and the last page
    if (pageCount <= pageLimit) {
      // If total pages are less than the limit, show all pages
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${1 === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          1
        </button>
      );

      // Show ellipsis if current page is far from the first page
      if (page > 3) {
        pageNumbers.push(<span key="start-ellipsis">...</span>);
      }

      // Show pages around the current page
      const startPage = Math.max(2, page - 1); // Start from one before the current page
      const endPage = Math.min(pageCount - 1, page + 1); // End at one after the current page

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is far from the last page
      if (page < pageCount - 2) {
        pageNumbers.push(<span key="end-ellipsis">...</span>);
      }

      // Show the last page
      pageNumbers.push(
        <button
          key={pageCount}
          onClick={() => handlePageChange(pageCount)}
          className={`mx-1 px-3 py-1 rounded ${pageCount === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          {pageCount}
        </button>
      );
    }

    return pageNumbers;
  };
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setPage(1)
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBulkIncomingAPI(
          page,
          ITEMS_PER_PAGE,
          filters,
          dispatch
        );
        const sorted = response.data.bulkIncomings.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        const rowsPerPage = 10;
        setPageCount(Math.ceil(response.data.pagination.totalCount / rowsPerPage));

        setData(sorted);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [page,filters]);
  return (
    <div className="bg-white shadow-md rounded-lg mt-6">
      {/* Filter Section */}
      <div className="flex items-center p-3 border-b gap-4">
      <input
          type="text"
          name="senderName"
          autoComplete="off"
          placeholder="Sender Name"
          value={filters.senderName}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />

        <input
          type="text"
          name="senderId"
          autoComplete="off"
          placeholder="Sender Id"
          value={filters.senderId}
          onChange={handleFilterChange}
          className="border p-2 rounded"
        />
      </div>

      {/* Scrollable Table */}
      <div className="overflow-x-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-50">
              {header?.map((text) => (
                <th
                  key={text}
                  className="px-4 py-2 font-semibold text-gray-700 text-sm"
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {

              data?.map((row, index) => (
                <tr
                  key={index}
                  className="border-b hover:bg-gray-50 transition-colors"
                >
                  <td className="px-4 py-2">{row.senderName}</td>
                  <td className="px-4 py-2">{row.senderId.slice(-6).toUpperCase()}</td>
                  <td className="px-4 py-2 text-green-500">AED {row.totalReceivedAmount}</td>
                  <td className="px-4 py-2 text-green-500">AED {row.totalEarnedAmount}</td>
                  <td className="px-4 py-2">{row.transactionCount}</td>
                  <td className="px-4 py-2">{row.totalBookings}</td>
                  {/* <td className="px-4 py-2 text-green-500">
                  AED {row.withdrawal}
                </td> */}
                  {/* <td className="px-4 py-2">
                    <span className="text-blue-500 cursor-pointer">Details</span>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center py-4">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowBack
            style={{
              color: "#076B9E",
            }}
          />
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowForward
            style={{
              color: "#076B9E",
            }}
          />
        </button>
      </div>
    </div>
  );
};
const OutgoingComponent = ({ header }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState({
    senderName: "",
    senderId: ""
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePageChange = (value) => {
    if (value < 1 || value > pageCount) return;
    setPage(value);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageLimit = 5; // Number of pages to show at once

    // Always show the first page, the current page, some surrounding pages, and the last page
    if (pageCount <= pageLimit) {
      // If total pages are less than the limit, show all pages
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${1 === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          1
        </button>
      );

      // Show ellipsis if current page is far from the first page
      if (page > 3) {
        pageNumbers.push(<span key="start-ellipsis">...</span>);
      }

      // Show pages around the current page
      const startPage = Math.max(2, page - 1); // Start from one before the current page
      const endPage = Math.min(pageCount - 1, page + 1); // End at one after the current page

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is far from the last page
      if (page < pageCount - 2) {
        pageNumbers.push(<span key="end-ellipsis">...</span>);
      }

      // Show the last page
      pageNumbers.push(
        <button
          key={pageCount}
          onClick={() => handlePageChange(pageCount)}
          className={`mx-1 px-3 py-1 rounded ${pageCount === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          {pageCount}
        </button>
      );
    }

    return pageNumbers;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchBulkOutgoingAPI(
          page,
          ITEMS_PER_PAGE,
          filters,
          dispatch
        );
        const sorted = response.data.bulkOutgoings.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        const rowsPerPage = 10;
        setPageCount(Math.ceil(response.data.pagination.totalCount / rowsPerPage));

        setData(sorted);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);
  return (
    <div className="bg-white shadow-md rounded-lg mt-6">
      {/* Filter Section */}
      <div className="flex justify-between items-center p-3 border-b">
        
      </div>

      {/* Scrollable Table */}
      <div className="overflow-x-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-50">
              {header?.map((text) => (
                <th
                  key={text}
                  className="px-4 py-2 font-semibold text-gray-700 text-sm"
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {

              data?.map((row, index) => (
                <tr
                  key={index}
                  className="border-b hover:bg-gray-50 transition-colors"
                >
                  <td className="px-4 py-2">{row.beneficiaryName}</td>
                  <td className="px-4 py-2">{row.beneficiaryId.slice(-6).toUpperCase()}</td>
                  <td className="px-4 py-2">{row.beneficiaryModel}</td>
                  <td className="px-4 py-2 text-green-500">AED {row.totalSentAmount}</td>
                  <td className="px-4 py-2">{row.transactionCount}</td>
                  {/* <td className="px-4 py-2 text-green-500">
                  AED {row.withdrawal}
                </td> */}
                  <td className="px-4 py-2">
                    <span className="text-blue-500 cursor-pointer">Details</span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center py-4">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowBack
            style={{
              color: "#076B9E",
            }}
          />
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowForward
            style={{
              color: "#076B9E",
            }}
          />
        </button>
      </div>
    </div>
  );
};
const TransactionsComponent = ({ header }) => {
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filters, setFilters] = useState({
    senderName: "",
    senderId: ""
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePageChange = (value) => {
    if (value < 1 || value > pageCount) return;
    setPage(value);
  };
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const pageLimit = 5; // Number of pages to show at once

    // Always show the first page, the current page, some surrounding pages, and the last page
    if (pageCount <= pageLimit) {
      // If total pages are less than the limit, show all pages
      for (let i = 1; i <= pageCount; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }
    } else {
      // Show the first page
      pageNumbers.push(
        <button
          key={1}
          onClick={() => handlePageChange(1)}
          className={`mx-1 px-3 py-1 rounded ${1 === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          1
        </button>
      );

      // Show ellipsis if current page is far from the first page
      if (page > 3) {
        pageNumbers.push(<span key="start-ellipsis">...</span>);
      }

      // Show pages around the current page
      const startPage = Math.max(2, page - 1); // Start from one before the current page
      const endPage = Math.min(pageCount - 1, page + 1); // End at one after the current page

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-1 rounded ${i === page
              ? "bg-[#F5F5F5] text-[#01A950] font-bold"
              : "bg-[#F5F5F5] text-[#2A2E33]"}
            `}
          >
            {i}
          </button>
        );
      }

      // Show ellipsis if current page is far from the last page
      if (page < pageCount - 2) {
        pageNumbers.push(<span key="end-ellipsis">...</span>);
      }

      // Show the last page
      pageNumbers.push(
        <button
          key={pageCount}
          onClick={() => handlePageChange(pageCount)}
          className={`mx-1 px-3 py-1 rounded ${pageCount === page
            ? "bg-[#F5F5F5] text-[#01A950] font-bold"
            : "bg-[#F5F5F5] text-[#2A2E33]"}
          `}
        >
          {pageCount}
        </button>
      );
    }

    return pageNumbers;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchTransactionsAPI(
          page,
          ITEMS_PER_PAGE,
          filters,
          dispatch
        );
        const sorted = response.data.transactions.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        const rowsPerPage = 10;
        setPageCount(Math.ceil(response.data.pagination.totalCount / rowsPerPage));

        setData(sorted);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [page]);
  return (
    <div className="bg-white shadow-md rounded-lg mt-6">
      {/* Filter Section */}
      <div className="flex justify-between items-center p-3 border-b">
        
      </div>

      {/* Scrollable Table */}
      <div className="overflow-x-auto max-h-96 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent">
        <table className="min-w-full text-left">
          <thead>
            <tr className="bg-gray-50">
              {header?.map((text) => (
                <th
                  key={text}
                  className="px-4 py-2 font-semibold text-gray-700 text-sm"
                >
                  {text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>

            {

              data?.map((row, index) => (
                <tr
                  key={index}
                  className="border-b hover:bg-gray-50 transition-colors"
                >
                  
                  <td className="px-4 py-2">{row.transactionId.slice(-6).toUpperCase()}</td>
                  <td className="px-4 py-2">{row.type}</td>
                  <td className="px-4 py-2">{row.status}</td>
                  <td className="px-4 py-2 text-green-500">AED {row.amount}</td>
                  <td className="px-4 py-2">{row.senderModel}</td>
                  <td className="px-4 py-2">{row.sender.slice(-6).toUpperCase()}</td>
                  <td className="px-4 py-2">{row.senderName}</td>
                  <td className="px-4 py-2">{row.beneficiaryModel}</td>
                  <td className="px-4 py-2">{row.beneficiary.slice(-6).toUpperCase()}</td>
                  <td className="px-4 py-2">{row.beneficiaryName}</td>
                  <td className="px-4 py-2">{row.bookingId.slice(-6).toUpperCase()}</td>
                 
                  {/* <td className="px-4 py-2 text-green-500">
                  AED {row.withdrawal}
                </td> */}
                  {/* <td className="px-4 py-2">
                    <span className="text-blue-500 cursor-pointer">Details</span>
                  </td> */}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-center py-4">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowBack
            style={{
              color: "#076B9E",
            }}
          />
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === pageCount}
          className="mx-2 bg-[#F5F5F5] p-2 rounded-lg disabled:opacity-50"
        >
          <IoIosArrowForward
            style={{
              color: "#076B9E",
            }}
          />
        </button>
      </div>
    </div>
  );
};

const Transactions = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [paymentSummery, setPaymentSummery] = useState({})
  const [bulkIncoming, setBulkIncoming] = useState([])
  const [bulkOutgoing, setBulkOutgoing] = useState([])
  const [transactions, setTransactions] = useState([])
  useEffect(() => {
    const fetchPaymentSummery = async () => {
      let res = await fetchPaymentSummeryAPI(dispatch);


      console.log(res.data);
      setPaymentSummery(res.data);
    };
    
   
    
    fetchPaymentSummery();
    // fetchBulkOutgoing();
    // fetchTransactions();
  }, [])
  const handleTabChange = (index) => setActiveTab(index);

 

  const stats = [
    { img: totalncomingImg, label: "Total Incoming", value: "23434" },
    { img: totalOutgoingImg, label: "Total Outgoings", value: "348" },
    { img: totaProfitImg, label: "Total Profit", value: "23132" },
    { img: totaEarningImg, label: "Total Earning", value: "348" },
    { img: totaPendingImg, label: "Payment Pendings", value: "348" },
  ];

  const tabs = [ "Incomings","Outgoings", "Transactions"];

  return (
    <div className="p-6 max-w-7xl mx-auto">
      {/* Statistics Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 mb-8">

        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totalncomingImg} alt={"Total Incoming"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Total Incoming"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.totalIncomings}
          </h3>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totalOutgoingImg} alt={"Total Outgoing"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Total Outgoing"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.totalOutgoings}
          </h3>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totaProfitImg} alt={"Platform Earnings"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Platform Earnings"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.platformEarnings}
          </h3>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totaPendingImg} alt={"Pending Payments"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Pending Payments"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.pendingPayments}
          </h3>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totaEarningImg} alt={"Withdrawn"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Withdrawn"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.totalWithdrawnAmount}
          </h3>
        </div>
        <div className="bg-white shadow-md rounded-lg p-4 flex flex-col items-center md:items-start justify-center" >
          <img src={totalncomingImg} alt={"Withdrawable"} className="w-14 h-14 mb-4" />
          <p className="text-sm text-gray-500 text-center md:text-left">
            {"Withdrawable"}
          </p>
          <h3 className="text-xl font-semibold text-center md:text-left">
            AED {paymentSummery.totalWithdrawableAmount}
          </h3>
        </div>
      </div>

      {/* Tabs Section */}
      <div className="border-b mb-4">
        <div className="flex space-x-6">
          {tabs.map((tab, index) => (
            <button
              key={index}
              onClick={() => handleTabChange(index)}
              className={`py-2 font-medium ${activeTab === index ? "text-black font-bold" : "text-gray-500"
                } text-sm xs:text-xs`}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      {activeTab === 0 &&
        <IncomingComponent
          header={[
            "Sender Name",
            "Sender ID",
            "Received Amount",
            "Earned Amount",
            "Transactions",
            "Bookings"
          ]}
        />
      }
      {activeTab === 1 &&
        <OutgoingComponent
          header={[
            "Beneficiary Name",
            "Beneficiary ID",
            "Beneficiary",
            "Sent Amount",
            "Transactions"
          ]}
          data={bulkOutgoing}
        />
      }

      {activeTab === 2 &&
        <TransactionsComponent
          header={[
            "Transaction Id",
            "Type",
            "Status",
            "Amount",
            "Sender",
            "Sender Id",
            "Sender Name",
            "Beneficiary",
            "Beneficiary Id",
            "Beneficiary Name",
            "Booking Id"
            
          ]}
          
        />
      }

      {/* Table Section */}

    </div>
  );
};

export default Transactions;

// Overview.js
import React, { useEffect, useState } from "react";
import Appointments from "./Appointments";
import MessageBox from "../components/MessageBox";
import { fetchAllStats } from "../api/bookings.api";
import { useDispatch } from "react-redux";
import { AppIcons } from "../assets";
import ReactEcharts from "echarts-for-react";
import AppConfig from "../config"
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import DoubleLineChart from "../components/DoubleLineChart";
import StarRating from "../components/StarRating";
import { fetchBookingStatsAPI, fetchBookingStatsByCategoryIdAPI, fetchCustomerStatsAPI, fetchProfessionalStatsAPI, fetchRevenueStatsAPI, fetchStatsAPI, fetchTopCompaniesStatsAPI } from "../api/overview.api";
import { fetchCategoriesAPI } from "../api/services.api";
const overviewData = [
  {
    name: "All Clients",
    count: "123",
  },
  {
    name: "All Appointments",
    count: "123",
  },
  {
    name: "All Services",
    count: "123",
  },
  {
    name: "All Staffs",
    count: "123",
  },
];

// const pieData = [
//   { name: "Company A", value: 30 },
//   { name: "Company B", value: 40 },
//   { name: "Company C", value: 20 },
//   { name: "Company D", value: 10 },
// ];

const customers = [
  { name: "Jane Cooper", id: "ID58723635", bookings: 12 },
  { name: "John Doe", id: "ID12345678", bookings: 5 },
  { name: "Alice Smith", id: "ID87654321", bookings: 3 },
  { name: "Michael Lee", id: "ID24680246", bookings: 2 },
  { name: "Sarah King", id: "ID10293847", bookings: 1 },
];

// ECharts Configuration for Line Chart
const getLineChartOptions = (title, stats) => ({
  tooltip: {
    trigger: "axis",
    backgroundColor: "rgba(255, 255, 255, 0.9)",
    borderColor: "#ccc",
    borderWidth: 1,
    textStyle: {
      color: "#333",
    },
    formatter: function (params) {
      const [dataPoint] = params;
      return `<strong>${dataPoint.name}</strong><br/>${dataPoint.seriesName}: ${dataPoint.value}`;
    },
    axisPointer: {
      type: "line",
      lineStyle: {
        color: "#FFA500", // Match the orange line color
      },
    },
  },
  xAxis: {
    type: "category",
    data: stats.map(x => x.name),
    axisLine: { lineStyle: { color: "#ccc" } },
    axisTick: { show: false },
    axisLabel: { color: "#333" },
  },
  yAxis: {
    type: "value",
    axisLine: { lineStyle: { color: "#ccc" } },
    axisTick: { show: false },
    axisLabel: { color: "#333" },
    splitLine: { lineStyle: { color: "#eee" } },
  },
  series: [
    {
      name: title,
      data: stats.map(x => x.revenue),
      type: "line",
      smooth: true,
      lineStyle: {
        color: "#FFA500", // Orange line color
        width: 3,
      },
      itemStyle: {
        color: "#FFA500", // Blue dot color
        borderWidth: 2,
        borderColor: "#FFFFFF", // Optional: white border around blue dots for better contrast
      },
      symbolSize: 8, // Dot size
      emphasis: {
        focus: "series",
      },
    },
  ],
});

const aboutCareArray = [
  {
    value: "homeCleaning",
    title: "Home Cleaning",
  },
  {
    value: "carWashing",
    title: "Car Washing",
  },
  {
    value: "petWashing",
    title: "Pet Washing",
  },
];

const sheduleArray = [
  {
    value: "today",
    title: "Today",
  },
  {
    value: "tomorrow",
    title: "Tomorrow",
  },
  {
    value: "thisWeek",
    title: "This Week",
  },
];

const GraphHeading = ({ dropDownSelectedId, setData, dropDownData, heading, total }) => {
  return (
    <div
      className="
    flex flex-wrap 
    items-center 
    justify-center sm:justify-between 
    gap-1 sm:gap-0
  "
    >
      <h6
        className="
      font-dm-sans 
      text-[18px] sm:text-[16px] 
      font-bold
    "
      >
        {heading}
      </h6>
      <h6
        className="
      font-dm-sans 
      text-[18px] sm:text-[16px] 
      font-bold
    "
      >
        {total}
      </h6>
      <div className="flex flex-row gap-2">
        <select
          id="aboutCare"
          name="aboutCare"
          className="
        w-full 
        border border-gray-300 rounded-md 
        p-2 bg-white
      "
          value={dropDownSelectedId}
          onChange={(e) =>
            setData(
              e.target.value,
            )
          }
        >
          {dropDownData.map(({ id, name }, index) => (
            <option key={index * Math.random()} value={id}>
              {name}
            </option>
          ))}
        </select>


      </div>


    </div>
  );
};

const Overview = () => {
  const dispatch = useDispatch();

  const [categories, setCategories] = useState([])
  const [stats, setStats] = useState({});
  const [bookingStats, setBookingStats] = useState([]);
  const [customerStats, setCustomerStats] = useState([]);
  const [professionalStats, setProfessionalStats] = useState([]);
  const [revenueStats, setRevenueStats] = useState([]);
  const [topCompaniesStats, setTopCompaniesStats] = useState([]);
  const [graph1Select, setGraph1Select] = useState({
    aboutCare: "homeCleaning",
    shedule: "today",
  });
  const [pieData, setPieData] = useState([])

  const [selectedCategoryIdBooking, setSelectedCategoryIdBooking] = useState(0)
  const [selectedCategoryIdRevenue, setSelectedCategoryIdRevenue] = useState(0)



  useEffect(() => {
    const fetchBookingStats = async () => {
      let res = []
      if (selectedCategoryIdBooking == 0) {
        res = await fetchBookingStatsAPI(dispatch);
      }
      else {
        res = await fetchBookingStatsByCategoryIdAPI(selectedCategoryIdBooking, dispatch);
      }

      console.log(res.data);
      setBookingStats(res.data);
    };
    fetchBookingStats();
  }, [selectedCategoryIdBooking])
  useEffect(() => {
    const fetchCategories = async () => {
      let res = await fetchCategoriesAPI(dispatch);

      setCategories([{ id: 0, name: "All" }, ...res.data]);
    };
    const fetchStats = async () => {
      let res = await fetchStatsAPI(dispatch);

      setStats(res.data);
    };
    const fetchBookingStats = async () => {
      let res = await fetchBookingStatsAPI(dispatch);

      setBookingStats(res.data);
    };
    const fetchCustomerStats = async () => {
      let res = await fetchCustomerStatsAPI(dispatch);

      setCustomerStats(res.data);
    };
    const fetchProfessionalStats = async () => {
      let res = await fetchProfessionalStatsAPI(dispatch);

      setProfessionalStats(res.data);
    };
    const fetchRevenueStats = async () => {
      let res = await fetchRevenueStatsAPI(dispatch);
      console.log(res.data);
      setRevenueStats(res.data);
    };
    const fetchTopCompaniesStats = async () => {
      let res = await fetchTopCompaniesStatsAPI(dispatch);
      console.log(res.data);
      if (res.data) {
        let totalRevenue = res.data?.reduce((sum, x) => sum + x.totalRevenue, 0);

        let pieDataMapped = res.data?.map(x => ({
          name: x.companyName,
          value: x.totalRevenue,
          percentage: ((x.totalRevenue / totalRevenue) * 100).toFixed(2) // Calculate percentage and round to 2 decimal places
        }));
        setPieData(pieDataMapped)

      }

      setTopCompaniesStats(res.data);
    };



    fetchCategories();
    fetchStats();
    fetchBookingStats();
    fetchCustomerStats();
    fetchProfessionalStats();
    fetchRevenueStats();
    if (!AppConfig.IS_COMP)
      fetchTopCompaniesStats();
  }, []);

  return (

    <div className="w-full h-full">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
        {/* Left Section: Statistics Cards */}
        <div className="col-span-12 md:col-span-5">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 h-full">
            {[
              {
                // icon: <PeopleAltIcon />,
                icon: AppIcons.User,
                label: "Total Professionals",
                value: stats.allProfessionals,
              },
              {
                // icon: <BusinessIcon />
                icon: AppIcons.Message,
                label: "Total Services",
                value: stats.allServices,
              },
              {
                icon: AppIcons.Offers,
                // <ShoppingBagIcon />,
                label: "Total Customers",
                value: stats.allCustomers,
              },
              {
                icon: AppIcons.Services,
                // <AssignmentIcon />
                label: "Total Bookings",
                value: stats.allBookings,
              },
            ].map((card, index) => (
              <div
                key={index}
                className={`h-full flex flex-col items-center md:items-start p-4 bg-white shadow-md rounded-lg`}
              >
                <div className="w-12 h-12  text-[#076B9E] flex items-center justify-center rounded-full mb-2">
                  {/* {card.icon} */}
                  <img
                    src={card.icon}
                    alt={""}
                    className="ml-4 w-14 h-14 rounded-full"
                  />
                </div>

                <h6 className="font-dm-sans text-lg">{card.label}</h6>
                <h4 className="font-dm-sans font-bold text-2xl">
                  {card.value}
                </h4>
              </div>
            ))}
          </div>
        </div>
        {/* Right Section: Graph 1 */}
        <div className="col-span-12 md:col-span-7">
          <div className="bg-white shadow-md p-6 h-full">
            <GraphHeading
              dropDownSelectedId={selectedCategoryIdRevenue}
              setData={setSelectedCategoryIdRevenue}
              dropDownData={categories}
              heading={"Total Revenue"}
              total={bookingStats?.statusMetrics?.totalCounts?.totalBookings}
            />
            <div className="mt-4">
              <ReactEcharts
                option={getLineChartOptions("Total Revenue", revenueStats)}
                style={{ height: 300 }}
              />
            </div>
          </div>
        </div>

        {/* left Section: Graph 2 */}
        <div className="col-span-12 md:col-span-7">
          <div className="bg-white shadow-md p-6 h-full">
            <GraphHeading
              dropDownSelectedId={selectedCategoryIdBooking}
              setData={setSelectedCategoryIdBooking}
              dropDownData={categories}
              heading={"Total Bookings"}
              total={bookingStats?.statusMetrics?.totalCounts?.totalBookings}
            />
            <div className="mt-4">
              <DoubleLineChart stats={bookingStats?.statusMetrics} />
            </div>
          </div>
        </div>

        {/* Left Section: Top 5 Customers */}
        <div className="col-span-12 md:col-span-5 md:mt-0">
          <div className="bg-white shadow-md p-6 h-full">
            <h5 className="font-dm-sans font-bold text-lg mb-4">
              Top 5 Customers
            </h5>
            <div className="flex justify-between items-center mb-2">
              <p className="text-sm sm:text-base font-dm-sans">Customer Name</p>
              <p className="text-sm sm:text-base font-dm-sans">
                No. of Bookings
              </p>
            </div>
            {customerStats.map((customer, index) => (
              <div key={index} className="flex items-center my-2">
                {/* <div className="h-9 w-9 rounded-[50%] bg-slate-300 flex justify-center items-center mr-2 text-white font-medium">
                {customer?.firstName?.slice(0, 1)?.toUpperCase() + customer?.lastName?.slice(0, 1)?.toUpperCase()}
                </div> */}
                <div>
                  <h6 className="font-dm-sans font-bold text-sm sm:text-base">
                    {customer.firstName + ' ' + customer.lastName}
                  </h6>
                  <div className="flex">

                    <p className="text-xs">{'ID: '}{customer.customerId.slice(-6).toUpperCase()}</p>
                  </div>

                </div>
                <p className="ml-auto font-dm-sans font-bold text-sm sm:text-base">
                  {customer.totalBookings}
                </p>
              </div>
            ))}
          </div>
        </div>

        {/* Right Section: Top 5 Service Providers */}
        {!AppConfig.IS_COMP &&
          <div className="col-span-12 md:col-span-7 mt-4">
            <div className="bg-white shadow-md p-6 h-full">
              <h6 className="font-semibold mb-4">Top 5 Service Providers</h6>
              <div className="flex flex-col-reverse sm:flex-row gap-4">
                <div className="flex-1">
                  <div className="flex items-center mb-2">
                    <p className="text-sm sm:text-base font-bold">Rank</p>
                    <p className="ml-4 text-sm sm:text-base font-bold">Company</p>
                    <p className="ml-auto text-sm sm:text-base font-bold">Total Revenue</p>
                  </div>
                  {pieData.map((dt, index) => (
                    <div key={index} className="flex items-center my-2">
                      <p className="text-sm sm:text-base">{index + 1}</p>
                      <div className="ml-4">
                        <p className="text-sm sm:text-base">
                          {dt.name}
                        </p>
                      </div>
                      <p className="ml-auto  text-sm sm:text-base">
                        {dt.value}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="w-full sm:w-1/2">
                  <ResponsiveContainer height={300}>
                    <PieChart>
                      <Pie data={pieData} dataKey="value" outerRadius={100}>
                        {pieData.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={
                              ["#FFBB28", "#FF8042", "#00C49F", "#0088FE"][index]
                            }
                          />
                        ))}
                        <LabelList
                          dataKey="percentage"
                          position="inside"
                          formatter={(percentage) => {
                            return `${percentage}%`; // Return the percentage with a % sign
                          }}
                          style={{
                            fill: "#2A2E33",
                            fontSize: "12px",
                            fontWeight: "bold",
                            stroke: "none",
                          }}
                        />
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        }

        {/* Right Section: Top 5 Service Providers */}
        <div class="col-span-12 md:col-span-5 mt-0 md:mt-2">
          <div className="h-full md:h-auto">
            <div className="bg-white shadow-md p-4 h-full">
              <h5 className="font-sans font-bold text-lg">Top 5 Professionals</h5>

              <div className="flex justify-between items-center my-2">
                <p className="font-sans text-sm sm:text-base">Professional Name</p>
                <p className="font-sans text-sm sm:text-base">
                  No. of Bookings
                </p>
              </div>

              {professionalStats.map((professional, index) => (
                <div className="flex items-center space-x-2 py-2" key={index}>
                  {/* Customer Info Section */}
                  <div className="flex items-center w-6/12">

                    <div>
                      <h6 className="font-sans font-bold text-sm sm:text-base">
                        {professional.firstName + ' ' + professional.lastName}
                      </h6>
                      <div >
                        <span className="font-sans text-xs">{professional.professionName}</span>
                        <br />
                        <span className="font-sans text-xs">{'ID: '}{professional.professionalId?.slice(-6).toUpperCase()}</span>
                      </div>

                    </div>
                  </div>

                  {/* Rating Section */}
                  <div className="w-3/12">
                    <StarRating
                      // name={`rating-${index}`}
                      value={5}
                    // readOnly
                    // sx={{ fontSize: { xs: "16px", sm: "18px" } }}
                    />
                  </div>

                  {/* Bookings Section */}
                  <div className="w-3/12 flex justify-end">
                    <p className="font-sans font-bold text-sm sm:text-base">
                      {professional.totalBookings}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;

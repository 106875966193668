import {fetchAllEntities} from "./baseHalo.api"

export const fetchPaymentSummeryAPI = async (dispatch) => {
    try {
      const response = await fetchAllEntities("payments/transactions/summary", dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchBulkIncomingAPI = async (pageNumber, rows, filters, dispatch) => {
    try {
      let queryString = `payments/transactions/bulk/incomings?page=${pageNumber}&limit=${rows}`;

    // Construct the query string based on the filters
    const filterParams = [];

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value) { // Only add to query string if the value is not empty
        filterParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    });

    // Append filter parameters if there are any
    if (filterParams.length > 0) {
      queryString += `&${filterParams.join('&')}`;
    }
    const response = await fetchAllEntities(queryString, dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchBulkOutgoingAPI = async (pageNumber, rows, filters, dispatch) => {
    try {
      
      let queryString = `payments/transactions/bulk/outgoings?page=${pageNumber}&limit=${rows}`;

    // Construct the query string based on the filters
    const filterParams = [];

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value) { // Only add to query string if the value is not empty
        filterParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    });

    // Append filter parameters if there are any
    if (filterParams.length > 0) {
      queryString += `&${filterParams.join('&')}`;
    }
    const response = await fetchAllEntities(queryString, dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
  export const fetchTransactionsAPI = async (pageNumber, rows, filters, dispatch) => {
    try { 
      
      let queryString = `payments/transactions?page=${pageNumber}&limit=${rows}`;

    // Construct the query string based on the filters
    const filterParams = [];

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value) { // Only add to query string if the value is not empty
        filterParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    });

    // Append filter parameters if there are any
    if (filterParams.length > 0) {
      queryString += `&${filterParams.join('&')}`;
    }
    const response = await fetchAllEntities(queryString, dispatch);
      
      return response;
    } catch (error) {
      throw new Error("Failed to fetch services: " + error.message);
    }
  };
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AppIcons } from "../../assets";
import FieldInput from "../../components/FieldInput";
import { FileTypes } from "../../utilities/constants";
import { pickFile } from "../../utilities/";
import { useDispatch, useSelector } from "react-redux";
import { createCategory, updateCategory } from "../../redux/actions/categoryActions";
import { selectImageUpload } from "../../redux/slices/imageUploadSlice";
import { uploadImageAction } from "../../redux/actions/imageUploadActions";
import { fetchImage } from "../../api/imageUpload.api";





function ProfileImage({ profilePicture, onChangeProfilePicture }) {
  // const { loading, error, image } = useSelector(selectImageUpload);
  // const dispatch = useDispatch();

  const [image, setImage] = useState(null);

  useEffect(() => {
    console.log("profilePicture", profilePicture);
    if (profilePicture) {
      setImage(profilePicture)}
  }, [profilePicture]);

  return (
    <div className="flex gap-3 justify-between self-start mt-16 font-semibold tracking-widest text-center text-white max-md:mt-10">
      
        <img
          loading="lazy"
          src={image || AppIcons.tempCat}
          alt="Profile Image"
          className="shrink-0 max-w-full rounded-full border-solid aspect-square border-[7px] border-zinc-100 w-[149px]"
        />
       
      <button
        onClick={onChangeProfilePicture}
        className="justify-center px-3 py-3 my-auto rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)]"
      >
        Change Picture
      </button>
    </div>
  );
}

function AddEditCategory() {
  const location = useLocation();
  const category = location.state;
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { loading, error, imageURL } = useSelector(selectImageUpload);

  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(null);
  const [coverPhotoURL, setCoverPhotoURL] = useState(null);

  useEffect(() => {
    if (category) {
      setCategoryName(category.name);
      setDescription(category.description);
      setCoverPhotoURL(category.image);
      setProfilePictureURL(category.icon);
      setIsActive(category.isActive);
      fetchImage(category.image)
      .then((img) => setCoverPhoto(img))
      fetchImage(category.icon)
      .then((img) => setProfilePicture(img))
    }
  }, [category]);

  // useEffect(() => {
  //   console.log("imageURL", imageURL);
  //   if (imageURL) {
  //     // Check the subfolder to determine which image URL it is
  //     if (imageURL.includes("cover")) {
  //       // Update cover photo URL state
  //       setCoverPhoto(imageURL);
  //     } else if (imageURL.includes("icon")) {
  //       // Update profile picture URL state
  //       setProfilePicture(imageURL);
  //     }
  //   }
  // }, [imageURL]);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

 

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  // Function to handle toggle of the switch
  const handleActiveToggle = () => {
    setIsActive((prevIsActive) => !prevIsActive);
  };

  

  const handleChangeProfilePicture = () => {
    pickFile([FileTypes.IMAGE], async (result) => {
      dispatch(uploadImageAction("categories", "icon", result,
        (path)=>{
          setProfilePictureURL(path)
          setProfilePicture(URL.createObjectURL(result))
        }
      ))
      dispatch(uploadImageAction("categories", "cover", result,
        (path)=>{
          setCoverPhotoURL(path)
          setCoverPhoto(URL.createObjectURL(result))
        }
      ))
    });
  };

  const handleSave = () => {

  
    const updatedCategoryData = {};
  
    // Check for each field if it's been updated, and if so, add it to the updatedCategoryData
    if (category?.name !== categoryName) {
      updatedCategoryData.name = categoryName;
    }

    if (!categoryName || !description ||!coverPhoto||!profilePicture) {
      alert("Please fill in all fields.");
      return;
    }
  
    updatedCategoryData.name = categoryName;
    updatedCategoryData.isActive = isActive;
    updatedCategoryData.description = description;
    updatedCategoryData.image = coverPhotoURL;
    updatedCategoryData.icon = profilePictureURL;
    
    console.log("Save me", updatedCategoryData);
  
    if (category) {
      // Update existing category
      updatedCategoryData.id = category.id;
      dispatch(updateCategory(updatedCategoryData));
    } else {
      // Create new category
      dispatch(createCategory({
        name: categoryName,
        isActive: isActive,
        description: description,
        image: coverPhotoURL,
        icon: profilePictureURL
      }));
    }
    navigate(-1)
  };
  

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <section className="flex flex-col px-8 py-8 text-sm bg-white rounded-3xl shadow-sm max-md:px-5 max-md:mt-10 max-md:max-w-full">
      
      <ProfileImage
        profilePicture={profilePicture}
        onChangeProfilePicture={handleChangeProfilePicture}
      />
      <div className="flex gap-5 items-start mt-3  tracking-widest max-md:flex-wrap max-md:max-w-full">
        <FieldInput
          label="Categories Name"
          value={categoryName}
          onChange={handleCategoryNameChange}
          placeholder="Enter category name"
        />
        <FieldInput
        label="Active/Inactive"
        value={isActive}
        onChange={handleActiveToggle}
        type="switch"
        placeholder="Active"
      />
      </div>
      <FieldInput
        label="Description"
        value={description}
        onChange={handleDescriptionChange}
        placeholder="Enter description"
        type="textarea"
      />
      
      <div className="flex gap-4 mt-16 ml-2.5 max-w-full text-sm font-bold text-white whitespace-nowrap tracking-[2px] w-[303px] max-md:mt-10">
        <button
          onClick={handleSave}
          className="justify-center items-start px-4 py-3 rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] max-md:px-5"
        >
          {category ? "Save" : "Add Categories"}
        </button>
        <button
          onClick={handleCancel}
          className="justify-center px-4 py-3 bg-rose-500 rounded-xl max-md:px-5"
        >
          Cancel
        </button>
      </div>
    </section>
  );
}

export default AddEditCategory;

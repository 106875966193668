import { fetchAllEntities } from "./baseHalo.api"

export const fetchBookingsAPI = async (pageNumber, rows, filters, dispatch) => {
  try {


    // Start with the base URL
    // Start with the base URL
    let queryString = `bookings/?page=${pageNumber}&limit=${rows}`;

    // Construct the query string based on the filters
    const filterParams = [];

    Object.keys(filters).forEach(key => {
      const value = filters[key];
      if (value) { // Only add to query string if the value is not empty
        filterParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
      }
    });

    // Append filter parameters if there are any
    if (filterParams.length > 0) {
      queryString += `&${filterParams.join('&')}`;
    }

    // Fetch data using the constructed query string
    const response = await fetchAllEntities(queryString, dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch companies: " + error.message);
  }
};


export const fetchAllStats = async (dispatch) => {
  try {

    const response = await fetchAllEntities(`stats`, dispatch);
    console.log(response)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch fetchAllStats: " + error.message);
  }
};


import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  staff: null, // Assuming staff is a single object; change to {} if it's an object
  staffList: [],
  loading: false,
  creating: false,
  updating: false,
  deleting: false,
  error: null,
  success: false,
};

export const StaffListSlice = createSlice({
  name: "staffList",
  initialState,
  reducers: {
    fetchStaffListStart(state) {
      state.loading = true;
      state.error = null;
      state.success = false;
    },
    fetchStaffListSuccess(state, action) {  
      state.staffList = action.payload.data;
      state.loading = false;
      state.success = true;
    },
    fetchStaffListFailure(state, action) {
      state.loading = false;
      state.error = action.payload.error;
      state.success = false;
    },
    createStaffStart(state) {
      state.creating = true;
      state.error = null;
      state.success = false;
    },
    createStaffSuccess(state, action) {
      state.staffList.push(action.payload.data); 
      state.creating = false;
      state.success = true;
    },
    createStaffFailure(state, action) {
      state.creating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    updateStaffImage(state, action) {
      const { id, imageBlob } = action.payload;
      state.staffList = state.staffList.map(staff =>
        staff.id === id ? { ...staff, image: imageBlob } : staff
      ); // Update the image for the specific staff
      state.success = true;
    },
    updateStaffStart(state) {
      state.updating = true;
      state.error = null;
      state.success = false;
    },
    updateStaffSuccess(state, action) {
      
      const updatedStaff = action.payload.data;
      state.staffList = state.staffList.map(staff =>
        staff.id === updatedStaff.id ? updatedStaff : staff
      ); 
      state.staff = updatedStaff;
      state.updating = false;
      state.success = true;
    },
    updateStaffFailure(state, action) {
      state.updating = false;
      state.error = action.payload.error;
      state.success = false;
    },
    deleteStaffStart(state) {
      state.deleting = true;
      state.error = null;
      state.success = false;
    },
    deleteStaffSuccess(state, action) {
      const idToDelete = action.payload.id;
      state.staffList = state.staffList.filter(staff => staff.id !== idToDelete);
      state.deleting = false;
      state.success = true;
    },
    deleteStaffFailure(state, action) {
      state.deleting = false;
      state.error = action.payload.error;
      state.success = false;
    },
  },
});

export const {
  fetchStaffListStart,
  fetchStaffListSuccess,
  fetchStaffListFailure,
  createStaffStart,
  createStaffSuccess,
  createStaffFailure,
  updateStaffStart,
  updateStaffSuccess,
  updateStaffFailure,
  deleteStaffStart,
  deleteStaffSuccess,
  deleteStaffFailure,
  updateStaffImage
} = StaffListSlice.actions;

export const selectStaffList = (state) => state.staffList;

export default StaffListSlice.reducer;

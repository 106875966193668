import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

// Sample data for the chart
const data = [
  { name: "Feb", bookings: 0, cancellations: 0 },
  { name: "Mar", bookings: 0, cancellations: 0 },
  { name: "Apr", bookings: 0, cancellations: 0 },
  { name: "May", bookings: 1890, cancellations: 4800 },
  { name: "Jun", bookings: 2390, cancellations: 3800 },
  { name: "Jul", bookings: 3490, cancellations: 4300 },
];

function DoubleLineChart({ stats}) {
  return (
    <div className="w-full h-[300px]">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={stats?.monthlyStats}
          margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="2 2" vertical={false} />
          <XAxis dataKey="name" tick={{ fill: "#6D6D6D" }} />
          <YAxis
            tick={{ fill: "#6D6D6D" }}
            axisLine={false} // Hides the Y-axis line
            tickLine={false} // Optional: also hide the small tick lines
          />
          <Tooltip
            contentStyle={{
              backgroundColor: "rgba(255, 255, 255, 0.9)",
              borderColor: "#ccc",
            }}
            formatter={(value, name) => [value, name]} // Simple formatting
            labelFormatter={(label) => `Month: ${label}`} // Customize label
          />
          <Legend verticalAlign="top" height={36} />
          {/* Bookings Line */}
          <Line
            type="monotone"
            dataKey="bookings"
            stroke="#00A9FF"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            dot={{ stroke: "#00A9FF", strokeWidth: 2, r: 4, fill: "#fff" }}
          />
          {/* Cancellations Line */}
          <Line
            type="monotone"
            dataKey="cancellations"
            stroke="#FFBB28"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            dot={{ stroke: "#FFBB28", strokeWidth: 2, r: 4, fill: "#fff" }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default DoubleLineChart;

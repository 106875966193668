// servicesAPI.js
import axios from "axios";
import { getAccessTokenFromCookie } from "../utilities/cookieUtils";

import {fetchAllEntities, createEntity,updateEntity, deleteEntity} from "./baseHalo.api"
import AppConfig from "../config"

export const fetchCategoriesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories", dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
//{{SERVER_ADDR}}/api/categories/65e8a98f5458d952434b7458/subcategories
export const fetchSubCategoriesByCategoryIDAPI = async (categoryId, dispatch) => {
  try {
    const response = await fetchAllEntities(`categories/${categoryId}/subcategories`, dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch subcategories: " + error.message);
  }
};

export const fetchSubCategoriesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/all", dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
//{{SERVER_ADDR}}/api/categories/subcategories/65e8ac395e7da6eb0ad099d0/services
export const fetchServicesBySubCategoryIDAPI = async (subCategoryId, dispatch) => {
  try {
    const response = await fetchAllEntities(`categories/subcategories/${subCategoryId}/services`, dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};

export const fetchServicesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/services/all", dispatch);
    
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};
//{{SERVER_ADDR}}/api/categories/subcategories/services/663dd41b6b355fe5b6af40cd/subservices
export const fetchSubServicesByServiceIDAPI = async (serviceId, dispatch) => {
  try {
    const response = await fetchAllEntities(`categories/subcategories/services/${serviceId}/subservices`, dispatch);
    return response;
  } catch (error) {
    throw new Error("Failed to fetch subservices: " + error.message);
  }
};

export const fetchSubServicesAPI = async (dispatch) => {
  try {
    const response = await fetchAllEntities("categories/subcategories/services/subservices/all", dispatch);
    
    return response;
  } catch (error) {
    throw new Error("Failed to fetch services: " + error.message);
  }
};



export const createCategoryAPI = async (data, dispatch) => {
  try {
    const response = await createEntity("categories", data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
};
export const createSubCategoryAPI = async (data, dispatch) => {
  try {
    const response = await createEntity(`categories/${data.category}/subcategories`, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
}
export const createSubServiceAPI = async (data, dispatch) => {
  try {
    const response = await createEntity(`categories/subcategories/services/${data.serviceId}/subservices`, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create sub service: " + error.message);
  }
};;
export const createServiceAPI = async (serviceData, dispatch) => {
  try {
    const response = await createEntity(`categories/subcategories/${serviceData.subCatId}/services`, serviceData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to create service: " + error.message);
  }
};
export const updateSubServiceAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories/services/subServices", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update sub service: " + error.message);
  }
};
export const updateServiceAPI = async (serviceData, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories/services", serviceData.id, serviceData, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};
export const updateCategoryAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};
export const updateSubCategoryAPI = async (data, dispatch) => {
  try {
    
    const response = await updateEntity("categories/subcategories", data.id, data, dispatch)
    return response;
  } catch (error) {
    throw new Error("Failed to update service: " + error.message);
  }
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices, filterServicesAction } from "../../redux/actions/servicesActions";
import { selectServices } from "../../redux/slices/servicesSlice";
import { AppIcons, AppImages } from "../../assets";
import "../../styles/services.style.css";
import { fetchImage } from '../../api/imageUpload.api'
import { selectSubCategories } from "../../redux/slices/subCategorySlice";
import { selectCategories } from "../../redux/slices/categorySlice"
import Dropdown from 'react-bootstrap/Dropdown';
import { fetchSubCategories } from "../../redux/actions/subCategoryAction";
import { fetchCategories } from "../../redux/actions/categoryActions";
import { selectProfessions } from "../../redux/slices/professionsSlice";
import { fetchProfessions } from "../../redux/actions/professionsActions";
import { fetchCategoriesAPI, fetchServicesBySubCategoryIDAPI, fetchSubCategoriesByCategoryIDAPI } from "../../api/services.api";

function ServiceCard({ catName, subCatName, service, onEdit }) {

  const { professions } = useSelector(selectProfessions);
  const { icon, isActive } = service;
  const [imageUrl, setImageUrl] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    fetchImage(icon)
      .then((img) => setImageUrl(img))

  }, [icon]);
  useEffect(() => {
    if (!professions || professions.length === 0) dispatch(fetchProfessions());

  }, [dispatch]);



  return (
    <div className="flex flex-col w-[24%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col flex-grow px-3 py-3  rounded-2xl border border-solid border-zinc-300 max-md:px-5 max-md:mt-4">
        <div className="flex gap-2 justify-between w-full text-base tracking-widest text-black max-md:mr-2.5">
          <div className="flex gap-1 justify-between">
            <img
              loading="lazy"
              src={imageUrl || AppIcons.tempCat}
              alt={service.name}
              className="flex-shrink-0 rounded-2 w-[90px] h-[59px] object-cover"
            />
            <div className="my-auto text-black ml-2 font-bold text-sm leading-normal tracking-wider">
              {service.name}
            </div>

          </div>
          <button
            className="shrink-0 my-auto aspect-square w-[21px]"
            onClick={() => onEdit(service)}
          >
            <img loading="lazy" src={AppIcons.Edit} alt="" />
          </button>
        </div>
        <p
          className={`${isActive == false ? "mt-2 text-red-500" : "mt-2 text-green-500"
            }`}
        >
          {isActive == false ? "In Active" : "Active"}
        </p>
        <div className="flex flex-col mt-4">

          <div className="flex text-sm flex-row justify-between">
            <p className="text-gray-500">Category</p>
            <p>{catName}</p>
          </div>
          <div className="flex flex-row text-sm justify-between">
            <p className="text-gray-500">Sub Category</p>
            <p>{subCatName}</p>
          </div>
          <div className="flex flex-row text-sm justify-between">
            <p className="text-gray-500">Profession</p>
            <p>{professions.find(
              (p) => p._id === service.profession
            )?.name}</p>
          </div>
        </div>

        {/* <div className="mt-2 text-base tracking-widest bg-clip-text amount">
          AED
          {service.pricePerHour}
        </div> */}
        <div className="mt-2 text-sm leading-6 text-black description">
          {service.description}
        </div>
      </div>
    </div>
  );
}

function Services() {
  
 
  const [categories, setCategories] = useState(null)
  const [subCategories, setSubCategories] = useState(null)
  const [services, setServices] = useState(null)

  const [category, setCategory] = useState(null)
  const [subCategory, setSubCategory] = useState(null)
  

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch categories once when the component mounts
    fetchCategoriesAPI(dispatch)
      .then((res) => {
        setCategories(res.data);

        // Select the first category by default if categories are available
        if (res.data && res.data.length > 0) {
          const firstCategory = res.data[0];
          setCategory(firstCategory);  // Set the first category
          // Fetch subcategories for the first category
          fetchSubCategoriesByCategoryIDAPI(firstCategory.id, dispatch)
            .then((data) => {
              setSubCategories(data.data); // Set the fetched subcategories in state
              // Select the first category by default if categories are available
              if (data.data && data.data.length > 0) {
                const firstSubCategory = data.data[0];
                setSubCategory(firstSubCategory);  // Set the first category
                // Fetch service for the first category
                fetchServicesBySubCategoryIDAPI(firstSubCategory.id, dispatch)
                  .then((srv) => {
                    setServices(srv.data); // Set the fetched subcategories in state
                  })
                  .catch((error) => {
                    console.error("Error fetching services:", error);
                  });
              }
            })
            .catch((error) => {
              console.error("Error fetching subcategories:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  function handleAddService() {
    console.log("Adding a new service");
    navigate("/services/new");
  }

  function handleEditService(service) {
    console.log("Editing service:", service);
    navigate(`/services/edit/${service?.id}`, { state: service });
  }
  const subCategorySelected = (obj) => {
    setSubCategory(obj)
    // Fetch services based on the selected sub category
    fetchServicesBySubCategoryIDAPI(obj.id, dispatch)
    .then((data) => {
        setServices(data.data); // Set the fetched subcategories in state
    })
    .catch((error) => {
        console.error("Error fetching services:", error);
    });
  }
  const categorySelected = (obj) => {
          setCategory(obj);
          // Fetch subcategories based on the selected category
          fetchSubCategoriesByCategoryIDAPI(obj.id, dispatch)
              .then((data) => {
                  setSubCategories(data.data); // Set the fetched subcategories in state
                  if (data.data && data.data.length > 0) {
                    const firstSubCategory = data.data[0];
                    setSubCategory(firstSubCategory);  // Set the first category
                    // Fetch service for the first category
                    fetchServicesBySubCategoryIDAPI(firstSubCategory.id, dispatch)
                      .then((srv) => {
                        setServices(srv.data); // Set the fetched subcategories in state
                      })
                      .catch((error) => {
                        console.error("Error fetching services:", error);
                      });
                  }
              })
              .catch((error) => {
                  console.error("Error fetching subcategories:", error);
              });
      };

  return (
    <section className="flex flex-col px-4 pt-7 pb-20 bg-white rounded-2xl shadow-sm max-md:px-4 max-md:mt-10 max-md:max-w-full">
      <header className=" logo-nav-flexbox-container flex gap-5 font-bold max-md:flex-wrap max-md:max-w-full">
        <div className="flex flex-col items-start mt-3 text-sm font-medium tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {category == null ? 'Select Category' : category.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {categories && categories.filter(x=>x.isActive).map((category, index) => (
                  <Dropdown.Item onClick={
                    () => {
                      categorySelected(category);
                    }

                  } >{category.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col items-start mt-3 text-sm font-medium w-full tracking-widest max-md:flex-wrap max-md:max-w-full">
          <label
            htmlFor="Category"
            className="text-black font-metropolis text-base font-normal leading-normal tracking-wide"
          >
            Sub Category
          </label>
          <div className=" grow justify-center items-start px-1 py-2 mt-1.5 w-half rounded-2xl border border-solid border-zinc-400 w-fit max-md:px-5 max-md:max-w-full">
            <Dropdown className="btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none">
              <Dropdown.Toggle className=" btn-dropdown-halo w-full h-full px-2 py-1 text-black font-metropolis text-sm font-semibold tracking-wide leading-normal border-none outline-none" variant="success" id="dropdown-basic">
                {subCategory == null ? 'Select Sub Category' : subCategory.name}
              </Dropdown.Toggle>

              <Dropdown.Menu className="btn-dropdown-list-halo">
                {subCategories && subCategories.filter(x=>x.isActive).map((subCategory, index) => (
                  <Dropdown.Item onClick={
                    () => {
                      subCategorySelected(subCategory);
                    }

                  } >{subCategory.name}</Dropdown.Item>

                ))}

              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <button
          onClick={handleAddService}
          className="justify-center px-3 py-2  text-sm text-white rounded-xl bg-[linear-gradient(276deg,#F0BA47_8.16%,#F3D25C_63.6%)] tracking-[2px] whitespace-nowrap"
        >
          Add New
        </button>
      </header>
      <div className="mt-8 max-md:mb-10 max-md:max-w-full">
        <div className="flex flex-wrap gap-2 max-md:flex-col max-md:gap-0">
          {services && services.map((service, index) => (
            <ServiceCard
              key={index}
              catName={category ? category.name : ''}
              subCatName={subCategory ? subCategory.name : ''}
              service={service}

              onEdit={handleEditService}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Services;
